import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PaymentMethodPage } from './payment-method.page';
import { MayaQrCodeComponent } from './maya-qr-code/maya-qr-code.component';
import { MayaPaymentProcessingComponent } from './maya-payment-processing/maya-payment-processing.component';

const routes: Routes = [
  {
    path: '',
    component: PaymentMethodPage
  },
  {
    path: 'qr-code-method',
    component: MayaQrCodeComponent
  },
  {
    path: 'maya-processing',
    component: MayaPaymentProcessingComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentMethodPageRoutingModule {}
